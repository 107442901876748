import useDevice from "hooks/useDevice"
import { FunctionComponent, KeyboardEvent, MouseEvent, ReactNode } from "react"

interface InputProps {
  label?: string
  disabled?: boolean
  isRequired?: boolean
  placeholder?: string | ReactNode
  meta?: any
  rest?: any
  input?: any
  helperText?: string
  icon?: any
  isNewOnboardingExp?: boolean
  max?: string
  min?: string
  onFocus?: () => void
  onBlur?: () => void
  inputCss?: string
  isWc?: boolean
  isSignNeeded?: boolean
}

const TextInput: FunctionComponent<InputProps> = ({
  input,
  meta,
  label,
  disabled,
  placeholder,
  helperText,
  icon,
  isNewOnboardingExp,
  min,
  max,
  isWc = false,
  onFocus,
  onBlur,
  inputCss = "",
  isSignNeeded = false,
}) => {
  const { isIos } = useDevice()
  const hasError = meta && meta.touched && meta.error
  const value = input && input.value !== ""
  const name = input && input.name

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      event.preventDefault() // Prevent default behavior (like form submission)
    }
  }

  return (
    <div className={`relative w-full ${isNewOnboardingExp ? "px-6" : ""}`}>
      <div
        className={
          value && !isNewOnboardingExp && !isWc ? "is-filled !w-full" : ""
        }
      >
        <input
          id={`field-${name}`}
          className={`h-12 relative px-4 w-full border ${
            isWc ? "border" : "border-2"
          } bg-white ${
            isNewOnboardingExp
              ? " focus:!border-[#BBAAEC] bg-transparent border-2 border-[#BBAAEC] placeholder:!text-[#686868] !text-white"
              : "pt-1"
          } ${
            isWc && "placeholder:!text-[#A5A4B6]"
          } rounded-lg text-base leading-tight ${
            hasError ? "border-red-600" : "border-[#CDCDCD]"
          } ${inputCss}`}
          placeholder={placeholder ? placeholder : label}
          disabled={disabled}
          min={min || ""}
          max={max || ""}
          {...input}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={handleKeyDown}
          // style={{ WebkitAppearance: "none", textAlignLast: "start" }}
        />
        {isSignNeeded && (
          <div className=" absolute slide-up top-[50%] translate-y-[-50%] right-16  text-[#8E8E8E]">
            %
          </div>
        )}
        {isIos && !input?.value && input?.type === "date" && (
          <p className=" absolute top-[50%] pl-4 text-[#686868]">
            {placeholder}
          </p>
        )}
        {!isNewOnboardingExp && !isWc && (
          <label
            className={`inputLabel absolute text-xxs inline-block px-4 ${
              isNewOnboardingExp
                ? "text-white left-[24px]"
                : "text-black left-0 "
            } opacity-0 cursor-auto top-0 mt-1 md:mt-2`}
            htmlFor={`field-${name}`}
          >
            {label}
          </label>
        )}
      </div>
      {icon && <div className="absolute top-0 right-0 mr-4 mt-4">{icon}</div>}
      {helperText && (
        <p className="text-sm text-lightgray-400 leading-tight mt-2">
          {helperText}
        </p>
      )}
      {hasError && !meta.active && (
        <p className="mt-1 text-xs text-red-600">{meta.error}</p>
      )}
      <style jsx>{`
        label {
          transition: all 200ms ease-in;
          transform: translate3d(0, 0.25rem, 0);
        }
        input {
          color: #616161;
          transition: all 200ms ease-out;
          box-sizing: border-box;
        }
        input:focus {
          box-shadow: 0px 2px 4px rgba(130, 136, 148, 0.16),
            0px 0px 1px rgba(130, 136, 148, 0.16);
          border-color: #443eff;
        }
        input:focus + .inputLabel {
          color: #443eff;
        }
        .is-filled input {
          padding-top: 1.25rem;
        }
        .is-filled label {
          opacity: 1;
          transform: translate3d(0, 0, 0);
        }
        input[type="number"]::-webkit-inner-spin-button,
        input[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
          -moz-appearance: none;
          appearance: none;
          margin: 0;
        }
        input::placeholder {
          color: #616161;
          opacity: 1;
        }
        @keyframes slideUp {
          0% {
            opacity: 0;
            transform: translateY(20px);
          }
          100% {
            opacity: 1;
            transform: translateY(-10px);
          }
        }

        .slide-up {
          animation: slideUp 300ms ease-out;
        }
      `}</style>
    </div>
  )
}

export default TextInput
